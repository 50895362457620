import React,{useEffect,useState} from 'react';
import {TableDatePicker} from '../function_lib/common_lib';
import DatePicker from "react-datepicker";


export function Comp_Edit_Submit_Fee(props){
    const [date, setDate] = useState(null);
    // alert(JSON.stringify(props.lsPaymentBreakdown));
    useEffect(() => {
        if(typeof props.childBtnFeeBreakups==="undefined"){
            return;
        }
        if(props.lsPaymentBreakdown.length>0){
            let fmtDate=new Date(props.lsPaymentBreakdown[0]["date"])
            setDate(fmtDate);
            }
        const clsPaymentBreakdown =props.childBtnFeeBreakups;
        clsPaymentBreakdown.setOnChangeEvent("fee_breakdown",clsPaymentBreakdown);
        clsPaymentBreakdown.purchaseItem.tt=[];
        for(let i=0;i<props.lsPaymentBreakdown.length;i++){
            let exPaymentBreakups = {};
            exPaymentBreakups["fee_category_name"]=props.lsPaymentBreakdown[i]["category_name"];
            exPaymentBreakups["fee_category_id"]=props.lsPaymentBreakdown[i]["fee_category_id"];
            exPaymentBreakups["install_amount"]=props.lsPaymentBreakdown[i]["installment"];
            exPaymentBreakups["no_installment"]=props.lsPaymentBreakdown[i]["no_installment"];
            exPaymentBreakups["total_amount"]=props.lsPaymentBreakdown[i]["balance"];
            exPaymentBreakups["ex_payment_entry_id"]=btoa(props.lsPaymentBreakdown[i]["payment_entry_id"]);
            exPaymentBreakups["ex_payment_breakdown_id"]=btoa(props.lsPaymentBreakdown[i]["id"]);
            clsPaymentBreakdown.purchaseItem.tt.push(exPaymentBreakups); 
        }
        clsPaymentBreakdown.setExistingData();
        //alert(props.lsPaymentBreakdown.length);
            clsPaymentBreakdown.preEventSetData=function setTotalAmount(){
            let totalAmount=0;
            for(let i=0;i<clsPaymentBreakdown.purchaseItem.tt.length;i++){
                totalAmount+=parseFloat(clsPaymentBreakdown.purchaseItem.tt[i]["total_amount"]);
                document.getElementById("net_amount").value=totalAmount;
            }
            document.getElementById("net_amount").value=totalAmount;
        }
        clsPaymentBreakdown.preEventSetData();
      }, [props.lsPaymentBreakdown]);
    
    return(<div className="modal fade bd-example-modal-xl"  id="divEditFeeSubmission" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Edit Fee Transactions</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        {/* form */}
        <div class="">
    
    {/* <style>
        .ui-menu{
            z-index:9999 !important;
        }
    </style> */}
    <section class="content">
        <div class="container-fluid">
        <div class="card card-secondary">
              {/* <!-- /.card-header --> */}
           
            {/* form data  */}
           
            <form class="form-group" id="frmEditFeeSubmission" name="frmEditFeeSubmission" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
            
                <input type="hidden" name="res_func" id="res_func" value="okSuccEditStuFee" />
                <input type="hidden" name="curl_type" id="curl_type" value="editStuFee" />
                <input type="hidden" name="m_type" id="m_type" value="editStuFee" />
                <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.app_user_id}/>
            <div class="card-body">  
            {props.lsPaymentBreakdown.length>0?<>   
            <div className="row">
            
<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<input type="hidden" id="e_payment_entry_id" name="e_payment_entry_id" defaultValue={btoa(props.lsPaymentBreakdown[0]["payment_entry_id"])}/>
<input type="hidden" id="e_stu_admis_id" name="e_stu_admis_id" defaultValue={btoa(props.lsPaymentBreakdown[0]["stu_admis_id"])}/>
<input type="hidden" id="e_invoice_id" name="e_invoice_id" defaultValue={btoa(props.lsPaymentBreakdown[0]["student_invoice_id"])}/>

<div className="form-group">
<label for="exampleInputEmail1">Branch</label>
         <div className="input-group">
             {props.lsPaymentBreakdown[0]["branch_name"]}
             </div>
         
         </div>
</div>

            <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Session Year</label>
                                            <div className="input-group input-group-sm">
                                            {props.lsPaymentBreakdown[0]["session_name"]}
                                            
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Class</label>
                                            <div className="input-group input-group-sm">
                                            {props.lsPaymentBreakdown[0]["class_name"]}
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Student Name</label>
                                            <div className="input-group input-group-sm">
                                                {props.lsPaymentBreakdown[0]["student_name"]}
                                                
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Date</label>
                                            <div className="input-group input-group-sm">
                                            <DatePicker className="form-control form-control-sm" dateFormat="dd/MM/yyyy" id={"ex_date"} name={"ex_date"}   format='dd/MM/yyyy' selected={date} onChange={date =>setDate(date)} />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Receipt No.</label>
                                            <div className="input-group input-group-sm">
                                            <input className="form-control required" defaultValue={props.lsPaymentBreakdown[0]["receipt_no"]} type="number" id="receipt_no" name="receipt_no"/>
                                            </div>
                                        </div>
                                  </div>

            </div>
            </>
            :''}
{/* end of first row */}
{/* table fee Breakdown */}
<div class="row">
<table class="table table-bordered table-sm">
                                        <thead>
                                            <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                <th>Fee Category</th>
                                                <th>Installment Amount</th>
                                                <th>No of Installment</th>
                                                <th>Total Amount</th>
                                                <th style={{"width":"40px"}}></th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <select id="fee_category_name" name="fee_category_name" val_memeber="fee_category_id" class="form-control piMember miMember">
                                                            <option value="">Select</option>
                                                          {props.lsFeeCategory.map((item,index)=>{
                                                            return <option value={item["id"]}>{item["category_name"]}</option>
                                                          })}
                                                        </select>
                                                    </div>
                                                </td>
                                                <td><div class="input-group input-group-sm"><input editable="true" type="text" id="install_amount" name="install_amount" required_mg="Installment Amount Required " relate_control="no_installment" output_to="total_amount"  class="form-control cd_input_change piMember miMember editable" /></div></td>
                                                <td><div class="input-group input-group-sm"><input editable="true" type="text" id="no_installment" name="no_installment" required_mg="No of Installment Required " relate_control="install_amount" output_to="total_amount" class="form-control cd_input_change piMember miMember editable" /></div></td>
                                                
                                                
                                                
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <input type="text" readOnly={"true"} id="total_amount" name="total_amount" class="form-control piMember miMember" />
                                                        <input type="hidden" id="fee_category_id" name="fee_category_id" class="piHidMember miMember" value="0" />
                                                    </div>
                                                    <input type="hidden" id="ex_payment_entry_id" name="ex_payment_entry_id" class="piHidMember miMember" value={btoa("0")}/>
                                                        <input type="hidden" id="ex_payment_breakdown_id" name="ex_payment_breakdown_id" class="piHidMember miMember" value={btoa("0")}/>
                                                </td>
                                                <td><input type="button" class="btn btn-block btn-info btn-sm" clmname="miMember" cldname="piMember" cldhidname="piHidMember" resValue="p_feeCategoryList" resname="tbFeeCatList" id="_btnFeeBreakdown" value="add" /></td>

                                            </tr>
                                        </thead>
                                        <tbody id="tbFeeCatList">
                                        </tbody>
                                    </table>
                                    <input type="hidden" id="fctmpt" name="fctmpt" value="0" />
                                    <input type="hidden" id="p_feeCategoryList" name="p_feeCategoryList" value="" />
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                    </div>
                                   
                                    <div class="col-md-10">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr style={{"background-color":"#FC766AFF"}}>
                                                    <th>Payment Mode</th>
                                                    <th colspan="2"></th>
                                                    <th>Total Payable</th>
                                                    
                                                </tr>
                                                <tr>
                                                  <td>
                                                  
                                            <div class="input-group input-group-sm">
                                                {props.lsPaymentBreakdown.length>0?
                                                    <select id="payment_mode" name="payment_mode" class="form-control" style={{"width":"100%"}}>
                                                    <option selected={props.lsPaymentBreakdown[0]["payment_mode"]===""} value="0">-Select-</option>
                                                    <option selected={props.lsPaymentBreakdown[0]["payment_mode"]==="Cash"} value="Cash">Cash</option>
                                                    <option selected={props.lsPaymentBreakdown[0]["payment_mode"]==="Cheque"} value="Cheque">Cheque</option>
                                                    <option selected={props.lsPaymentBreakdown[0]["payment_mode"]==="Bank Transfer"} value="Bank Transfer">Bank Transfer</option>
                                                    <option selected={props.lsPaymentBreakdown[0]["payment_mode"]==="UPI"} value="UPI">UPI</option>
                                                    <option selected={props.lsPaymentBreakdown[0]["payment_mode"]==="Credit Note"} value="Credit Note">Credit Note</option>
                                                    <option selected={props.lsPaymentBreakdown[0]["payment_mode"]==="Debit Note"} value="Debit Note">Debit Note</option>
                                                    </select>:''}
                                            </div>
                                        
                                                  </td>
                                                  <td colspan="2"><div class="input-group input-group-sm"><input type="text" class="form-control" id="narration" name="narration" defaultValue={props.lsPaymentBreakdown.length>0?props.lsPaymentBreakdown[0]["narration"]:''} /></div></td>
                                                    <td><div class="input-group input-group-sm"><input id="net_amount" readOnly={"true"} rfnode="value" name="net_amount" type="text" class="form-control" /></div></td>
                                                </tr>
                                            </thead>
                                        
                                        </table>
                                        
                                    </div>
                                </div>

            </div>
            <div class="row">
                <div class="col-9"></div>
                    <div class="col-3">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" class="btn btn-sm btn-danger _btnSetColor">Submit Fee</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
            </form>
            {/* end of form data */}
                
              {/* <!-- /.card-body --> */}
            </div>
        </div>
    </section>
</div>

        {/* end of form */}
      </div>
      </div>
      </div>)
}

export default Comp_Edit_Submit_Fee;