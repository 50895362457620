import React,{useEffect} from 'react';
import './comp_marksheet_pro_one.css';
import logoImg from '../assets/images/logo.png';
import ResPicDef from '../assets/images/schoo_pic.png';
import {bindEdit} from '../function_lib/common_lib';


function exportPdf(pageType) {
    var whe = window.innerHeight;
    var wwid = window.innerWidth;

    let data = document.getElementById("divPrintMarkSheetContent").innerHTML;
    /*
    alert(data);
    var doc = new DOMParser().parseFromString(data, "text/xml");

    alert(doc.getElementsByTagName("<div>")[1]);
    */
    var printWindow = window.open('', '', 'width=' + (wwid - 20) + ',height=' + (whe - 10) + '');
    printWindow.document.write('<html><head><title>Export Data</title>');
    //printWindow.document.write('<link rel="stylesheet" href="~/plugins/fontawesome-free/css/all.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://cdn.datatables.net/1.12.1/css/jquery.dataTables.min.css">');
    printWindow.document.write('<link rel="stylesheet" href="comp_marksheet_pro_one.css">');
    printWindow.document.write('<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700">');
    if(pageType==="1"){
        printWindow.document.write('<style>@page { size: landscape; }table, th, td {border: 0.5px solid;}table{width:100%;border-spacing:0;}</style>');
    }else{
        printWindow.document.write('<style>table, th, td {border: 0.5px solid;}table{width:100%;border-spacing:0;}</style>');
    }
    
    
    printWindow.document.write('</head><body >');
    printWindow.document.write(data);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    //alert(printWindow.document.getElementById("exp_body").innerHTML);
    //printWindow.document.getElementById("exp_body").removeAttribute("style");
    //delete cell from table
    // let tbList = printWindow.document.getElementById("ptrTable");
    // let tbRow = tbList.rows;
    // //alert(tbRow.length);
    // for (let i = 0; i < tbRow[0].cells.length; i++) {
    //     var str = tbRow[0].cells[i].innerHTML;
    //     //alert(str);
    //     if (str.search("Action") != -1) {
    //         for (var j = 0; j < tbRow.length; j++) {

    //             // Deleting the ith cell of each row
    //             tbRow[j].deleteCell(i);
    //         }
    //     }
    // }

    setTimeout(function () {
        printWindow.print();
    }, 500);
    return false;
}

const Comp_Marksheet_Pro_One=(props)=>{
    useEffect(()=>{
        if(props.lsStudentBasic.length>0){
            bindEdit();
        }
        //alert(JSON.stringify(props.lsStudentBasic));
        // alert(JSON.stringify(props.editItem));
    },[props.lsStudentBasic])
    return(
        <div className="modal fade bd-example-modal-xl"  id="divPrintMarkSheet" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Export Report</h5>
          <a href={"javascript:void(0)"} onClick={()=>exportPdf(props.pageType)} className="btn btn-sm btn-danger float-right">Export/Print</a>

          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
     
        <div id="divPrintMarkSheetContent" className="modal-body">
        {props.lsStudentBasic.length>0?
        <div class="report-card akshatinternational">
        <div class="header">
            <img src={logoImg} alt="School Logo" class="logo"/>
            <img src={ResPicDef} alt="School Emblem" class="logo-right"/>
            <div class="school-name">CITY MONTESSORI HIGH SCHOOL</div>
            <div class="school-address">Affiliated to MP Board, Affiliation No. 21128264</div>
            <div class="school-info">90 Quarter, Indira Nagar, Ujjain (M.P.)</div>
            <div class="school-info">Email ID: citymontessori786@gmail.com</div>
            <div class="school-info">Website: www.citymontessori.com</div>
        </div>

        <div class="report-title">ANNUAL REPORT CARD</div>
        <div class="academic-session">Academic Session: {props.editItem["session_name"]}</div>

        <table class="student-info">
            <tr>
                <th colspan="2">Student Profile</th>
                <th colspan="2"></th>
            </tr>
            <tr>
                <td>Name</td>
                <td>{props.editItem["student_name"]}</td>
                <td>Roll No.</td>
                <td>{props.editItem["scholar_no"]}</td>
            </tr>
            <tr>
                <td>Date of Birth</td>
                <td>{props.editItem["fmt_dob"]}</td>
                <td>Class & section</td>
                <td>{props.editItem["class_name"]+" Sec."+props.editItem["section"]}</td>
            </tr>
            <tr>
                <td>Father's Name</td>
                <td>{props.editItem["father_name"]}</td>
                <td>Admission No</td>
                <td>{props.editItem["scholar_no"]}</td>
            </tr>
            <tr>
                <td>Mother's Name</td>
                <td>{props.editItem["mother_name"]}</td>
                <td colspan="2"></td>
            </tr>
        </table>

        <div class="academic-section">
            <div class="academic-header">Academic Part</div>
            <table class="academic-table">
                <tr>
                    <th rowspan="2">Subjects</th>
                    <th colspan="2">Theory Exam</th>
                    <th colspan="2">Practical and Project</th>
                    <th colspan="2">Total</th>
                    <th rowspan="2">Grade</th>
                </tr>
                <tr>
                    <th>Maximum</th>
                    <th>Obtain Marks</th>
                    <th>Maximum</th>
                    <th>Obtain Marks</th>
                    <th>Maximum</th>
                    <th>Obtain Marks</th>
                </tr>
                <tr>
                    <td>English</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">80</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">72</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">20</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">20</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">100</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">92</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">A1</td>
                </tr>
                <tr>
                    <td>Hindi</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">80</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">77</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">20</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">20</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">100</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">97</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">A1</td>
                </tr>
                <tr>
                    <td>Mathematics</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">80</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">74</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">20</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">20</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">100</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">94</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">A1</td>
                </tr>
                <tr>
                    <td>Social Science</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">70</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">61</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">30</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">27</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">100</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">88</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">A2</td>
                </tr>
                <tr>
                    <td>Science</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">80</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">77</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">20</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">20</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">100</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">97</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">A1</td>
                </tr>
                <tr>
                    <td>Sanskrit</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">80</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">77</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">20</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">20</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">100</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">97</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">A1</td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">390</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">361</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">110</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">107</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">500</td>
                    <td class="editable" e_type="text" e_key="f" e_id="ft">468</td>
                    <td>A1</td>
                </tr>
            </table>
        </div>

        <table class="grand-total">
            <tr>
                <td>Grand total</td>
                <td>468/500</td>
                <td>Percentage</td>
                <td>93.6%</td>
                <td>Total Grade</td>
                <td>A1</td>
            </tr>
        </table>
    <table>
        <tr>
            <td>
        <div class="co-scholastic">
            <div class="co-scholastic-title academic-header">Co-Scholastic Activities
                <br/>
                (3 Point Grading Scale A,B,C)
            </div>
            <table class="traits-table">
                <tr>
                    <th></th>
                    <th>Work Education</th>
                    <th>Art Education</th>
                    <th>Health & Physical Education</th>
                    <th>Scientific Skills</th>
                    <th>Thinking Skills</th>
                    <th>Social Skills</th>
                    <th>Sports</th>
                </tr>
                <tr>
                    <td>T1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                </tr>
                <tr>
                    <td>T2</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                </tr>
                <tr>
                    <td>T3</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                </tr>
                
            </table>
        </div>
</td>
<td>
<div class="co-scholastic">
            <div class="co-scholastic-title academic-header">Discipline
                <br/>
                (3 Point Grading Scale A,B,C)
            </div>
            <table class="traits-table">
                <tr>
                    <th></th>
                    <th>Work Education</th>
                    <th>Art Education</th>
                    <th>Health & Physical Education</th>
                    <th>Scientific Skills</th>
                    <th>Thinking Skills</th>
                    <th>Social Skills</th>
                    <th>Sports</th>
                </tr>
                <tr>
                    <td>T1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                </tr>
                <tr>
                    <td>T2</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                </tr>
                <tr>
                    <td>T3</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                    <td>A1</td>
                </tr>
                
            </table>
        </div>
    </td>
</tr>
</table>


        <table class="attendance-table">
            <tr>
                <td>Attendance</td>
                <td>162/199</td>
                <td>Result</td>
                <td>Passed and Promoted to Class XII</td>
                <td>Remark</td>
                <td>School will reopen on 5th April, 2024</td>
            </tr>
        </table>

        <div class="signature-section">
            <div class="signature">
                <div class="signature-line"></div>
                <div>Class Teacher</div>
            </div>
            <div class="signature">
                <div class="signature-line"></div>
                <div>Examination I/C</div>
            </div>
            <div class="signature">
                <div class="signature-line"></div>
                <div>Principal</div>
            </div>
        </div>

        <div class="grade-scale-section">
            <table class="grade-scale">
                <tr>
                    <th colspan="9">Scholastic Subject</th>
                    <th colspan="6">Co-Scholastic Topics</th>
                </tr>
                <tr>
                    <th>Marks Range</th>
                    <th>91-100</th>
                    <th>81-90</th>
                    <th>71-80</th>
                    <th>61-70</th>
                    <th>51-60</th>
                    <th>41-50</th>
                    <th>33-40</th>
                    <th>0-32</th>
                    <th>Grade Range</th>
                    <th>5</th>
                    <th>4</th>
                    <th>3</th>
                    <th>2</th>
                    <th>1</th>
                </tr>
                <tr>
                    <th>Grade</th>
                    <td>A1</td>
                    <td>A2</td>
                    <td>B1</td>
                    <td>B2</td>
                    <td>C1</td>
                    <td>C2</td>
                    <td>D</td>
                    <td>E</td>
                    <th>Grade</th>
                    <td>A1</td>
                    <td>A2</td>
                    <td>B1</td>
                    <td>B2</td>
                    <td>C</td>
                </tr>
            </table>
        </div>

    </div>:'No Details Found'}

    </div>

    </div>
      </div>
      </div>
    );
}

export default Comp_Marksheet_Pro_One;