import './comp_view_student_card.css';

const Comp_View_Student_Card=(props)=>{
    
    // alert(JSON.stringify(props.studentList));
    return(<div class="book-detail-card">
      {props.studentList.map((item,index)=>{
       
        //let topCats=item["top_cats"].split('<');
        return <div class="book-card bg-white mb-3">
          <a  href={"javascript:void(0)"} data-bs-toggle="modal" data-bs-target="#divPdfView">
        <div class="row g-0">
          {/* <!-- Icon --> */}
          <div class="col-lg-2 col-md-2 col-sm-6 left-section">
            <span class="badge bg-primary">{index+1}</span>
            <div class="book-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
              </svg>
            </div>
            <div class="book-code text-center mt-2">Form No.: {item["form_no"]}<br/>Scholar No: {item["scholar_no"]}</div>
            <div class="book-code text-center mt-2">Admission Date.: {item["fmt_admit_date"]}<br/>DOB : {item["fmt_dob"]}</div>
          </div>
          
          {/* <!-- Info --> */}
          <div class="col-lg-3 col-md-3 col-sm-6 text-start">
            <div class="p-3">
              <h6 class="book-title mb-2">{item["student_name"]}</h6>
              <p class="book-author mb-2">Admission Type: <span class="book-title">{item["admission_type"]}</span></p>
              <p class="book-author mb-2">Admission Status: <span class="book-title">{item["is_active"]}</span></p>
              <div class="book-categories">
               
                {/* {item["top_cats"]} */}
                 <span class="book-category frst-category">Class: {item["class_name"]}</span>
                <span class="book-category secnd-category">Section: {item["section"]}</span>
                <span class="book-category third-category">Shift :{item["shift"]}</span>
              </div>
              <div class="book-categories">
               
               {/* {item["top_cats"]} */}
                <span class="book-category frst-category">Gender: {item["gender"]}</span>
               <span class="book-category secnd-category">Cast: {item["stu_cast"]}</span>
               <span class="book-category third-category">Category :{item["stu_category"]}</span>
             </div>
              <p class="book-author mb-2">WhatsApp No: <span class="book-title">{item["whats_no"]}</span></p>
              <p class="book-author mb-2">Alt.Contact No: <span class="book-title">{item["alt_contact_no"]}</span></p>

              
            </div>
          </div>
          {/* family details */}
          <div class="col-3 col-md-3 text-start">
            <div class="p-3">
              <h6 class="book-title mb-2">Father Name: {item["father_name"]}</h6>
              <p class="book-author mb-2">Father's Occupation: <span class="book-title">{item["father_occup"]}</span></p>
              <p class="book-author mb-2">Father Job Type: <span class="book-title">{item["father_job_type"]}</span></p>
              <hr/>
              <h6 class="book-title mb-2">Mother Name: {item["mother_name"]}</h6>
              <p class="book-author mb-2">Mother's Occupation: <span class="book-title">{item["mother_occup"]}</span></p>
              <p class="book-author mb-2">Mother Job Type: <span class="book-title">{item["mother_job_type"]}</span></p>
              <div class="book-categories">
               
                {/* {item["top_cats"]} */}
                 {/* <span class="book-category frst-category">Class: {item["class_name"]}</span>
                <span class="book-category secnd-category">Section: {item["section"]}</span>
                <span class="book-category third-category">Shift :{item["shift"]}</span> */}
              </div>
            </div>
          </div>

          {/* Authorization details */}
          <div class="col-lg-3 col-md-3 col-sm-6 text-start">
            <div class="p-3">
              <h6 class="book-title mb-2">Student SSMID: {item["stu_ssmid"]}</h6>
              <p class="book-author mb-2">Student FamilyId: <span class="book-title">{item["stu_family_id"]}</span></p>
              <p class="book-author mb-2">Student Aadhaar: <span class="book-title">{item["stu_aadhaar_no"]}</span></p>
              <div class="book-categories">
               <h6>Bank Details:</h6>
               <p>
                Account No: <span class="book-title">{item["stu_bank"]}</span><br/>
                Bank Name: <span class="book-title">{item["bank_name"]}</span><br/>
                Branch Name: <span class="book-title">{item["stu_bank_branch"]}</span><br/>
                IFSC: <span class="book-title">{item["stu_ifsc_code"]}</span><br/>
               </p>
                {/* {item["top_cats"]} */}
                
              </div>
            </div>
          </div>
        {/* Action */}
        <div class="col-3 col-md-3 text-start">
            <div class="p-3">
              <div class="book-categories">
              <div class="btn-group">
                    <button type="button"  class="btn btn-warning btn-flat dropdown-toggle" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    {typeof props.isMarksheet==="undefined"?
                    <div role="menu" class="dropdown-menu">
                   
                     <a class="dropdown-item" onClick={()=>props.onEditClick(item)} data-toggle="modal" data-target="#divEditStudentBasic" href="javascript:void(0)">View/Edit Basic Details</a>
                     <a class="dropdown-item" onClick={()=>props.onEditClick(item)} data-toggle="modal"  data-target="#divEditStudentFamily" href="javascript:void(0)">View/Edit Family Details</a>
                     <a class="dropdown-item" onClick={()=>props.onEditClick(item)} data-toggle="modal" data-target="#divEditStudentAuth" href="javascript:void(0)">View/Edit Authorization</a>
                     <a class="dropdown-item" onClick={()=>props.onEditClick(item)} data-toggle="modal" data-target="#divEditFullStudent" href="javascript:void(0)">View/Edit All Details</a>

                     {typeof props._objAccess.can_delete!=="undefined"?<a class="dropdown-item red" onClick={()=>props.onDeleteStuComp(item)} href="javascript:void(0)">Delete Student</a>:''}
                     <div class="dropdown-divider"></div>
                     {/* <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal" data-target="#divEditStudentAuth" href="javascript:void(0)">Promot to Next</a>
                     <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal" data-target="#divEditStudentAuth" href="javascript:void(0)">TC/SLC Process</a> */}

                    </div>:

<div role="menu" class="dropdown-menu">
                   
<a class="dropdown-item" onClick={()=>props.onEditClick(item)} data-toggle="modal" data-target="#divPrintMarkSheet" href="javascript:void(0)">Enter Marks</a>
<a class="dropdown-item" onClick={()=>props.onEditClick(item)} data-toggle="modal" data-target="#divPrintMarkSheet" href="javascript:void(0)">Print MarkSheet</a>
<a class="dropdown-item" onClick={()=>props.onEditClick(item)} data-toggle="modal" data-target="#divEditFullStudent" href="javascript:void(0)">View/Edit All Details</a>


</div>
                    
                    }
                  </div> 
              
              </div>
            </div>
          </div>


        </div>
        </a>
      </div>
      
      })}
            
        </div>
     

    );
}

export default Comp_View_Student_Card;