import $, { extend } from 'jquery';
import React,{useEffect,useState} from 'react'
import {btnSetColor_CBack,TableDatePicker,FormatDate} from '../function_lib/common_lib';


export function Comp_Edit_Full_Student(props){
    const [dates, setDates] = useState({
        edit_dob: "",
        admit_date: "",
      });
      const [stuFamData,setStuFamData]=useState({});
      const [stuAuthData,setStuAuthData]=useState({});  

      const handleDateChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value.replace(/\D/g, "");
    
        if (formattedValue.length >= 2) formattedValue = formattedValue.slice(0, 2) + '/' + formattedValue.slice(2);
        if (formattedValue.length >= 5) formattedValue = formattedValue.slice(0, 5) + '/' + formattedValue.slice(5);
    
        setDates({
          ...dates,
          [name]: formattedValue
        });
      };

    //alert(JSON.stringify(props.lsStudentBasic));
    useEffect(()=>{
        if(props.lsStudentBasic.length>0){


            //set family details
            if(props.editFullStudentDet.ls_stu_family.length>0){
                setStuFamData(props.editFullStudentDet.ls_stu_family[0]);
            }
            if(props.editFullStudentDet.ls_stu_family.length===0){
                setStuFamData({});
            }

            //set authorization details
            if(props.editFullStudentDet.ls_stu_auth.length>0){
                setStuAuthData(props.editFullStudentDet.ls_stu_auth[0]);
            }
            if(props.editFullStudentDet.ls_stu_auth.length===0){
                setStuAuthData({});
            }

            let strAdmitDate="";
            let strDob="";
            if(props.lsStudentBasic[0]["admit_date"]!==null){
                strAdmitDate=FormatDate(props.lsStudentBasic[0]["admit_date"]);
             }
            if(props.lsStudentBasic[0]["dob"]!==null){
                strDob=FormatDate(props.lsStudentBasic[0]["dob"]);
            }
            setDates({
                ...dates,
                ["edit_dob"]: strDob,
                ["admit_date"]: strAdmitDate
              });
            //alert(JSON.stringify(dates));
            
            
        }else{
            setDates({
                ...dates,
                ["admit_date"]: "",
                ["edit_dob"]:""
              });
        }

    },[props.studentId,props.lsStudentBasic])
    return(<div className="modal fade bd-example-modal-xl"  id="divEditFullStudent" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Edit Basic Details :{props.editItem.student_name}</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        {/* form */}
        <div class="">
    
    {/* <style>
        .ui-menu{
            z-index:9999 !important;
        }
    </style> */}
    <section class="content">
        <div class="container-fluid">
        {props.lsStudentBasic.length>0?
            <form class="form-group" id="frmEditFullStudent" name="frmEditFullStudent">
                <input type="hidden" name="res_func" id="res_func" value="stubasic_okSucc" />
                <input type="hidden" name="curl_type" id="curl_type" value="updateStudentFull" />
                <input type="hidden" name="m_type" id="m_type" value="updateStudentFull" />
                <input type="hidden" name="stu_id" id="stu_id" value={btoa(props.lsStudentBasic[0]["id"])}/>
                <input type="hidden" name="fam_id" id="fam_id" value={typeof stuFamData.id!=="undefined"?btoa(stuFamData.id):"0"} />
                <input type="hidden" name="auth_student_id" id="auth_student_id" value={typeof stuAuthData.student_id!=="undefined"?btoa(stuAuthData.student_id):btoa(props.studentId)} />
                <input type="hidden" name="collection_status" id="collection_status" value="Pending Accession" />
                <input type="hidden" name="e_ref_id" id="e_ref_id" />
                <input type="hidden" name="e_party_id" id="e_party_id"/>

               
                <div class="row">
                    {/* <!-- left column --> */}
                    <div class="col-md-12">
                        <div class="card card-danger">
                   
                            <div class="card-body">
                           {/* start card-body */}
                           <div className="row">
                                <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Admission Date (DD/MM/YYY)</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" onChange={handleDateChange} value={dates.admit_date} id="admit_date" name="admit_date" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/MM/yyyy" data-mask="true" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  {/* <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Form No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="form_no" name="form_no" defaultValue={props.lsStudentBasic[0]["form_no"]} className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div> */}
                                  <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Scholar No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="scholar_no" name="scholar_no" defaultValue={props.lsStudentBasic[0]["scholar_no"]} className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Admission Type *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="admission_type" name="admission_type" className="form-control required">
                                                  <option value="0">-Select Reg./RTE-</option>
                                                  <option selected={props.lsStudentBasic[0]["admission_type"]==="Regular"} value="Regular">Regular Admission</option>
                                                  <option selected={props.lsStudentBasic[0]["admission_type"]==="RTE"} value="RTE">RTE Admission</option>
                                                  {/* {this.state.stReceiptAmount.map((item,index)=>{
                                                    return <option value={item["id"]+","+item["amount"]}>{item["name"]}</option>
                                                  })} */}
                                                </select>
                                            </div>
                                        </div>
                                  </div>

                                  {/* <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">S.No</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="sno" name="sno" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div> */}

                                  {/* <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">OPD No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="opd_no" name="opd_no" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div> */}
                                
                                </div>
                                <div class="row">
                                <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Student Name</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="student_name" name="student_name" defaultValue={props.lsStudentBasic[0]["student_name"]} className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Father's Name</label>
                                          <div className="input-group input-group-sm">
                                              <input type="text" defaultValue={props.lsStudentBasic[0]["father_name"]} id="father_name" name="father_name" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Gender</label>
                                            <div className="input-group input-group-sm">
                                            <select id="gender" name="gender" className="form-control required11">
                                                  <option value="">-Select Category-</option>
                                                  {props.genderList.map((item,index)=>{
                                                    return <option selected={props.lsStudentBasic[0]["gender"]===item} value={item}>{item}</option>
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div> 
                                <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">DOB(DD/MM/YYYY)</label>
                                            <div className="input-group input-group-sm">
  <input type="text"  id="edit_dob" name="edit_dob" onChange={handleDateChange} value={dates.edit_dob} data-inputmask-alias="datetime"  data-inputmask-inputformat="dd/MM/yyyy" data-mask="true" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Category</label>
                                            <div className="input-group input-group-sm">
                                                <select className="form-control" id="stu_cast_category" name="stu_cast_category">
                                                  <option value={""}>-Select-</option>
                                                  {props.castCategoryList.map((item,index)=>{
                                                    return <option selected={props.lsStudentBasic[0]["stu_cast_category"]===item} value={item}>{item}</option>
                                                  })}
                                                </select>

                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Cast</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="stu_cast" name="stu_cast" defaultValue={props.lsStudentBasic[0]["stu_cast"]} className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                 
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Session Year</label>
                                            <div className="input-group input-group-sm">
                                             <select id="session_year_id" name="session_year_id" className="form-control required form-control-sm">
                                               <option value={"0"}>-Session Year-</option>
                                               {props.lsSessionYear.map((item,index)=>{
                                                return <option selected={props.lsStudentBasic[0]["session_year_id"]===item["id"]} value={item["id"]}>{item["session_name"]}</option>
                                               })}
                                             </select>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Class</label>
                                            <div className="input-group input-group-sm">
                                            <select id="class_id" name="class_id" className="form-control required form-control-sm">
                                               <option value={"0"}>-Classes-</option>
                                               {props.lsClassMaster.map((item,index)=>{
                                                return <option selected={props.lsStudentBasic[0]["class_id"]===item["id"]} value={item["id"]}>{item["class_name"]}</option>
                                               })}
                                             </select>
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Section</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="section" name="section" defaultValue={props.lsStudentBasic[0]["section"]} className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Shift</label>
                                            <div className="input-group input-group-sm">
                                            <select id="shift" name="shift" className="form-control form-control-sm">
                                                <option value={"0"}>-Select-</option>
                                                <option selected={props.lsStudentBasic[0]["shift"]==="First"} value={"First"}>-First-</option>
                                                <option selected={props.lsStudentBasic[0]["shift"]==="Second"} value={"Second"}>-Second-</option>
                                            </select>
                                                
                                            </div>
                                        </div>
                                  </div>
         
                                </div>  
                        <div className="row">
                        <div className="col-lg-3 col-md-3">
                             <div className="form-group">
                                            <label for="exampleInputEmail1">WhatsApp No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="whats_no" name="whats_no" defaultValue={props.lsStudentBasic[0]["whats_no"]} className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                             <div className="form-group">
                                            <label for="exampleInputEmail1">Alt. Contact No.</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="alt_contact_no" name="alt_contact_no" defaultValue={props.lsStudentBasic[0]["alt_contact_no"]} className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>
                      </div>
                      <div className="row">    
                          <div className="col-lg-3 col-md-3">
                             <div className="form-group">
                                            <label for="exampleInputEmail1">Place of Birth</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="place_of_birth" name="place_of_birth" defaultValue={props.lsStudentBasic[0]["place_of_birth"]} className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>
                          <div className="col-lg-2 col-md-2">
                             <div className="form-group">
                                            <label for="exampleInputEmail1">Total Siblings</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="total_siblings" name="total_siblings" defaultValue={props.lsStudentBasic[0]["total_siblings"]} className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">No of Sibling Study in Same School</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="no_of_sibling" name="no_of_sibling" defaultValue={props.lsStudentBasic[0]["no_of_sibling"]} className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>
                        </div>        
<hr/>
                        {/* family details */}
                        <div class="row">
         

                        <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Father's Occupation</label>
                                          <div className="input-group input-group-sm">
                                              <input type="text" id="father_occup" defaultValue={stuFamData["father_occup"]} name="father_occup" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div> 
                        <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Job Type</label>
                                          <div className="input-group input-group-sm">
                                              <select id="father_job_type" name="father_job_type" className="form-control">
                                                  <option value={""}>-Select Job Type-</option>
                                                  <option selected={stuFamData["father_job_type"]==="Private"} value={"Private"}>-Private Service-</option>
                                                  <option selected={stuFamData["father_job_type"]==="Government"} value={"Government"}>-Government Service-</option>
                                              </select>
                                          </div>
                                      </div>
                        </div> 
           
          </div>

          <div class="row">
          <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Mother's Name</label>
                                          <div className="input-group input-group-sm">
                                              <input type="text" defaultValue={stuFamData["mother_name"]} id="mother_name" name="mother_name" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div>

                        <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Mother's Occupation</label>
                                          <div className="input-group input-group-sm">
                                              <input type="text" defaultValue={stuFamData["mother_occup"]} id="mother_occup" name="mother_occup" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div> 
                        <div className="col-lg-3 col-md-3">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Job Type</label>
                                          <div className="input-group input-group-sm">
                                              <select id="mother_job_type" name="mother_job_type" className="form-control">
                                                  <option value={""}>-Select Job Type-</option>
                                                  <option selected={stuFamData["mother_job_type"]==="Private"} value={"Private"}>-Private Service-</option>
                                                  <option selected={stuFamData["mother_job_type"]==="Government"} value={"Government"}>-Government Service-</option>
                                                  <option selected={stuFamData["mother_job_type"]==="House Wife"} value={"House Wife"}>-House Wife-</option>
                                              </select>
                                          </div>
                                      </div>
                        </div> 
           
          </div>
          {/* address */}
          <div class="row">
          <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Residential Address</label>
                                          <div className="input-group input-group-sm">
                                              <input type="text" defaultValue={stuFamData["resi_address"]} id="resi_address" name="resi_address" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                                          <label for="exampleInputEmail1">Permanent Address</label>
                                          <div className="input-group input-group-sm">
                                              <input type="text" defaultValue={stuFamData["perm_address"]} id="perm_address" name="perm_address" className="form-control required11" placeholder="" />
                                          </div>
                                      </div>
                        </div> 
                        
           
          </div>
          {/* address */}
          
       
                        {/* end of family details */}


{/* Authorization details  */}
<hr/>
<div class="row">
            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">SSSMID</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" defaultValue={stuAuthData["stu_ssmid"]} id="stu_ssmid" name="stu_ssmid" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Family ID</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" defaultValue={stuAuthData["stu_family_id"]} id="stu_family_id" name="stu_family_id" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Aadhaar No</label>
                                            <div className="input-group input-group-sm">
                                              <input type="text" defaultValue={stuAuthData["stu_aadhaar_no"]} id="stu_aadhaar_no" name="stu_aadhaar_no" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
             
            </div>

            <div class="row">
            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Bank Name</label>
                                            <div className="input-group input-group-sm">
                                                <input defaultValue={stuAuthData["bank_name"]!==null?stuAuthData["bank_name"]:''} type="text" id="bank_name" name="bank_name" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>
            <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Bank Account</label>
                                            <div className="input-group input-group-sm">
                                                <input defaultValue={stuAuthData["stu_bank"]} type="text" id="stu_bank" name="stu_bank" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Bank Branch</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" defaultValue={stuAuthData["stu_bank_branch"]} id="stu_bank_branch" name="stu_bank_branch" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
                          <div className="col-lg-3 col-md-3">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">IFSC Code</label>
                                            <div className="input-group input-group-sm">
                                               <input type="text" defaultValue={stuAuthData["stu_ifsc_code"]} id="stu_ifsc_code" name="stu_ifsc_code" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div> 
             
            </div>
            
            <div class="row">
            <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Previous School Attend if Any</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" defaultValue={stuAuthData["pre_school_name"]} id="pre_school_name" name="pre_school_name" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                                            <label for="exampleInputEmail1">Last Examination Passed Class</label>
                                            <div className="input-group input-group-sm">
                                              <select id="last_exam_class_id" name="last_exam_class_id" className="from-control form-control-sm required11">
                                                <option value={"0"}>-select-</option>
                                                {props.lsClassMaster.map((item,index)=>{
                                                  return <option selected={stuAuthData["last_exam_class_id"]===item["id"]} value={item["id"]}>{item["class_name"]}</option>
                                                })}

                                              </select>
                                                
                                            </div>
                                        </div>
                          </div> 
                          
             
            </div>
{/* Edit Authorization details */}

                           {/* edn of card-body */}
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" onClick={()=>props.editStudentBasic("frmEditFullStudent")} class="btn btn-primary _btnSetColor11">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
                {/* <!-- /.card-body --> */}
            </form>:''}
        </div>
    </section>
</div>

        {/* end of form */}
      </div>
      </div>
      </div>)
}

export default Comp_Edit_Full_Student;