import React from 'react';
import { findDOMNode } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Popper from 'popper.js';
import logo from './logo.svg';
import './App.css';

import Pages from './pages';

import DashboardNew from './user_pages/dashboard_new';
import Student_Admission from './user_pages/student_admission';
import Session_Year from './user_pages/session_year';
import Fee_Categories from './user_pages/fee_categories';
import Class_Master from './user_pages/class_master';
import Class_Fee_Master from './user_pages/class_fee_master';
import View_Class_Fee_Master from './user_pages/view_class_fee_master';

import View_Student_Admission from './user_pages/view_student_admission';
import View_Student_Admission_New from './user_pages/view_student_admission_new'; 

import View_Fee_Transactions from './user_pages/view_fee_transactions';
import View_Fee_Breakups_Trans from './user_pages/view_fee_breakups_trans';
import View_Student_Wise_Due from './user_pages/view_student_wise_due';
import Student_Tc_Proc from './user_pages/student_tc_proc';
import Student_Tc_List from './user_pages/student_tc_list';
import Promot_Student from './user_pages/promot_student';
import View_Class_Wise_Due from './user_pages/view_class_wise_due';
import Student_Fee_Allotment from './user_pages/student_fee_allotment';
import Submit_Fee from './user_pages/submit_fee';
import Student_Bulk_Import from './user_pages/student_bulk_import';
import View_Student_Transportation from './user_pages/view_student_transportation';
import Transportation_Master from './user_pages/transportation_master';

import View_Employee from './user_pages/view_employee';

import Marks_Entry from './user_pages/marks_entry';
import Tmp_Test from './user_pages/tmp_test';



class App extends React.Component{
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
  render(){
   
    return <Router>
      <Route exact path="/" component={Pages} />
     
      <Route exact path="/Dashboard" component={DashboardNew}/>
      <Route exact path="/student_admission" component={Student_Admission} />
      {/* <Route exact path="/opd_reports" component={Opd_Reports} /> */}
      {/* <Route exact path="/ipd_reports" component={Ipd_Reports} /> */}
      <Route exact path="/session_year" component={Session_Year} />
      <Route exact path="/fee_categories" component={Fee_Categories} />
      <Route exact path="/class_master" component={Class_Master} />
      <Route exact path="/class_fee_master" component={Class_Fee_Master} />
      <Route exact path="/view_class_fee_master" component={View_Class_Fee_Master} />
      
      {/* <Route exact path="/view_student_admission" component={View_Student_Admission}/> */}
      <Route exact path="/view_student_admission" component={View_Student_Admission_New} />
      <Route exact path="/marks_entry" component={Marks_Entry}/>
      
      <Route exact path="/student_fee_allotment" component={Student_Fee_Allotment}/>
      <Route exact path="/Submit_Fee" component={Submit_Fee}/>
      <Route exact path="/student_bulk_import" component={Student_Bulk_Import}/>
      <Route exact path="/view_fee_transactions" component={View_Fee_Transactions} />
      <Route exact path="/view_fee_breakups_trans" component={View_Fee_Breakups_Trans}/>
      <Route exact path="/view_student_wise_due" component={View_Student_Wise_Due}/>
      <Route exact path="/student_tc_proc" component={Student_Tc_Proc} />
      <Route exact path="/student_tc_list" component={Student_Tc_List}/>
      <Route exact path="/promot_student" component={Promot_Student} />
      <Route exact path="/view_class_wise_due" component={View_Class_Wise_Due}/>
      <Route exact path="/view_student_transport" component={View_Student_Transportation}/>
      <Route exact path="/transport_master" component={Transportation_Master}/>
      <Route exact path="/view_employee" component={View_Employee}/>
      <Route exact path="/tmp_test" component={Tmp_Test}/>
    
    </Router>
  }
  
}
export default App;